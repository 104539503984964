import { OptionalKeys, RequiredKeys } from 'ts-essentials';

export const propsEqual = <T>(a: T, b: T, props: Array<keyof T>) => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }
  return props.every((prop) => a[prop] === b[prop]);
};

type PickRequiredNotUndefinable<T> = {
  [Key in RequiredKeys<T> as Extract<undefined, T[Key]> extends never ? Key : never]: undefined;
};

type PickRequiredUndefinable<T> = {
  [Key in RequiredKeys<T> as Extract<undefined, T[Key]> extends never ? never : Key]: undefined;
};

/**
 * Utils for working with properties of objects of type `T`.
 */
export class PropUtils<T extends { [key: string | symbol | number]: any }> {
  private readonly requiredNotUndefinablePropKeys: ReadonlyArray<keyof T>;
  private readonly requiredUndefinablePropKeys: ReadonlyArray<keyof T>;
  private readonly requiredUndefinablePropKeysSet: Set<keyof T>;
  public readonly allPropKeys: ReadonlyArray<keyof T>;

  constructor(
    public readonly requiredNotUndefinablePropsSample: PickRequiredNotUndefinable<T>,
    public readonly requiredUndefinablePropsSample: PickRequiredUndefinable<T>,
    public readonly optionalPropsSample: { [Key in OptionalKeys<T>]: undefined }
  ) {
    this.requiredNotUndefinablePropKeys = Reflect.ownKeys(requiredNotUndefinablePropsSample);
    this.requiredUndefinablePropKeys = Reflect.ownKeys(requiredUndefinablePropsSample);
    this.requiredUndefinablePropKeysSet = new Set(this.requiredUndefinablePropKeys);
    const optionalKeys = Reflect.ownKeys(optionalPropsSample);
    this.allPropKeys = [...this.requiredNotUndefinablePropKeys, ...this.requiredUndefinablePropKeys, ...optionalKeys];
  }

  /**
   * Makes a copy of the object, keeping only properties declared in `T`.
   * All `undefined` optional properties are removed.
   */
  readonly pickAllProps = <Superset extends T>(object: Superset) =>
    this.allPropKeys.reduce((result, propKey) => {
      if (object.hasOwnProperty(propKey)) {
        const value = object[propKey];
        if (value !== undefined || this.requiredUndefinablePropKeysSet.has(propKey)) {
          result[propKey] = value;
        }
      }
      return result;
    }, {} as T);

  /**
   * Checks if objects has all required properties of `T`.
   */
  readonly hasAllRequiredProps = (object: Partial<T>): object is T =>
    this.requiredNotUndefinablePropKeys.every((key) => object[key] !== undefined) &&
    this.requiredUndefinablePropKeys.every((key) => object.hasOwnProperty(key));
}
